export default class ChatLicense {
	
	static LICENSE_TYPES = {
		0: "starter",
		1: "advanced",
		2: "ultimate",
	};
	
	static MONTH_COUNT = [ 1, 12 ];
	
	/**
	 *
	 * @param chat {chat}
	 */
	constructor(chat) {
		this.chat = chat;
	}
	
	get isLicenseExpired() {
		return !this.chat.license_left > 0;
	}
	
	get licenseExpiresAt() {
		return this.chat.license_timestamp;
	}
	
	get licenseType() {
		return ChatLicense.LICENSE_TYPES[ this.chat.license_type ];
	}
	
	get expiresSoon() {
		return parseInt(parseInt(this.chat.license_left) / (3600 * 24)) < 7;
	}
	
}
